<template>
  <div id="berita-acara-document" :style="format">
    <div class="header">
      <div style="margin-right: 30px;">
        <img
          :src="institusi.logo || `/${config.logoCompress}`"
          width="120px"
          height="90px"
          alt
        />
      </div>
      <div class="text-center">
        <div class="text-uppercase font-size-18 mb-1">
          {{ institusi ? institusi.nama : configSchool.name2 }}
        </div>
        <div class="font-size-12">
          {{ institusi ? institusi.alamat.lokasi : configSchool.address }}
        </div>
        <div class="font-size-12">
          Telp. {{ noTelp }} Fax. {{noFax}}
        </div>
      </div>
    </div>
    <hr />
    <div>
      <div class="text-center mb-3">
        <div>BERITA ACARA</div>
        <div>{{dataPrint.detailTipe}} ({{dataPrint.tipe}})</div>
        <div>TAHUN PELAJARAN {{dataPrint.tahun}}</div>
      </div>
      <div class="font-size-12 mb-3">
        Pada hari ini, {{waktuUjian.hari}} tanggal {{waktuUjian.tanggal}} bulan {{waktuUjian.bulan}} tahun {{waktuUjian.tahun}}, di {{institusi ? institusi.nama : configSchool.name2}} telah diselenggarakan {{dataPrint.detailTipe}} ({{dataPrint.tipe}}), dari pukul {{waktuUjian.jamMulai}} sampai dengan pukul {{waktuUjian.jamSelesai}}
      </div>
    </div>
    <div class="font-size-12 mb-4">
      <div class="d-flex mb-2">
        <div class="pr-3">1.</div>
        <div>
          <div class="d-flex">
            <div style="width: 200px;">Mata Pelajaran</div>
            <div class="pr-2">:</div>
            <div>{{dataPrint.mapel}}</div>
          </div>
          <div class="d-flex">
            <div style="width: 200px;">Kelas</div>
            <div class="pr-2">:</div>
            <div>{{dataPrint.kelas}}</div>
          </div>
          <div class="d-flex">
            <div style="width: 200px;">Jumlah Peserta Seharusnya</div>
            <div class="pr-2">:</div>
            <div>{{dataPrint.totalPeserta}}</div>
          </div>
          <div class="d-flex">
            <div style="width: 200px;">Jumlah Hadir (ikut ujian)</div>
            <div class="pr-2">:</div>
            <div>{{dataPrint.countPresent}}</div>
          </div>
          <div class="d-flex">
            <div style="width: 200px;">Jumlah Tidak Hadir</div>
            <div class="pr-2">:</div>
            <div>{{dataPrint.countAbsent}}</div>
          </div>
        </div>
      </div>
      <div class="d-flex mb-2">
        <div class="pr-3">2.</div>
        <div>
          Catatan selama pelaksanaan ujian :
        </div>
      </div>
      <div id="box-berita-acara" class="p-2">{{dataPrint.inputBeritaAcaraRoot || 'lancar'}}</div>
    </div>
    <div class="font-size-12">
      <div class="d-flex justify-content-between" style="width: 75%;">
        <div style="max-width: 250px;" class="mb-3">
          <div class="mb-3">
            Yang membuat berita acara:
          </div>
          <div class="d-flex">
            <div style="min-width: 100px">Pengawas</div>
            <div class="pr-2">:</div>
            <div>{{dataPrint.namaPengawas || '-'}}</div>
          </div>
          <div class="d-flex">
            <div style="min-width: 100px">NIK</div>
            <div class="pr-2">:</div>
            <div>{{dataPrint.nikPengawas || '-'}}</div>
          </div>
        </div>
        <div style="max-width: 200px;" class="d-flex flex-column align-items-center">
          <div class="text-center">
            TTD Pengawas
          </div>
          <div style="height: 60px"></div>
          <div>{{dataPrint.namaPengawas || '-'}}</div>
        </div>
      </div>
    </div>
    <div style="width: 335px; font-size: 9px" id="catatan">
      <table class="table table-bordered">
        <tr><td>Catatan:</td></tr>
        <tr style="min-height: 40px"><td>- Diisi dan dicetak setelah semua peserta selesai mengerjakan ujian</td></tr>
      </table>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import JsPdf from 'jspdf'
import moment from 'moment'
export default {
  props: {
    format: {
      type: Object,
      // required: true,
    },
    isPrint: {
      type: Boolean,
      defaultValue: false,
    },
    dataPrint: {
      type: Object,
      // required: true,
    },
  },
  data() {
    return {
      baseUrl: window.location.origin,
      config: config.school,
      pageHeight: 1098,
    }
  },
  methods: {
    previewDoc() {
      const doc = new JsPdf({
        // orientation: 'p',
        unit: 'px',
        // format: 'a4',
        // putOnlyUsedFonts: true,
      })
      const data = document.getElementById('berita-acara-document')
      // console.log(data)4
      doc.html(data, {
        callback: (doc) => {
          window.open(URL.createObjectURL(doc.output('blob')))
          this.$emit('printDone')
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
          scale: 0.6,
        },
        margin: [10, 10, 10, 10],
      })
    },
  },
  computed: {
    institusi() {
      return this.$store.state.master.institusi
    },
    noTelp() {
      let noTelp = this.institusi?.no_hp || this.config.number
      if (this.institusi?.no_hp) {
        const daerah = noTelp.slice(0, 3)
        noTelp = `(${daerah}) ${noTelp.slice(3)}`
      }
      return noTelp
    },
    noFax() {
      let noFax = this.institusi?.fax || this.config.number
      if (this.institusi?.fax) {
        const daerah = noFax.slice(0, 3)
        noFax = `(${daerah}) ${noFax.slice(3)}`
      }
      return noFax
    },
    waktuUjian() {
      const { detailHari, ujianStart, durasi } = this.dataPrint
      const hari = detailHari
      const momentUjianAwal = moment(ujianStart)
      const bulan = ujianStart ? momentUjianAwal.format('MM') : null
      const tanggal = ujianStart ? momentUjianAwal.format('DD') : null
      const tahun = ujianStart ? momentUjianAwal.format('YYYY') : null
      const jamMulai = ujianStart ? momentUjianAwal.format('HH:mm') : null
      const jamSelesai = ujianStart ? momentUjianAwal.add(durasi, 'minutes').format('HH:mm') : null
      const obj = {
        hari,
        bulan,
        tanggal,
        tahun,
        jamMulai,
        jamSelesai,
      }
      return obj
    },
  },
  watch: {
    isPrint(newVal) {
      if (newVal) {
        this.previewDoc()
      }
    },
  },
}
</script>

<style lang="scss">
#berita-acara-document {
  .header {
    display: flex;
    width: 80%;
  }
  #box-berita-acara {
    border: 0.5px solid black !important;
    min-height: 150px;
    max-height: 200px;
  }
  #catatan {
    margin-top: 150px;
  }
  // border: 1px solid black;
  hr { border: 0.5px solid black !important; }
  padding: 40px 30px;
  .header-title {
    font-size: 18px;
    letter-spacing: 2px;
    border: 1px solid black;
    padding: 5px 10px;
  }

  p {
    font-family: "Montserrat", sans-serif !important;
    font-size: 14px!important;
  }

  .table-bordered {
    border: 0.5px solid black;
    td, th {
      border: 0.5px solid black;
    }
    // & > tr {
    //   & > td {
    //     border-top-width: 0;
    //     border-left-width: 0;

    //     &:last-child {
    //       border-right-width: 0;
    //     }
    //   }

    //   &:last-child {
    //     & > td {
    //       border-bottom-width: 0;
    //     }
    //   }
    // }
  }

  .signature {
    padding: 0 80px
  }

  // ol {
  //   li {
  //     // padding-top: 7px;
  //    div {
  //      padding-top: 4px;
  //    }
  //   }
  // }
}
</style>
